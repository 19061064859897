<template>
  <div class="lesson-avialable">
    <div class="title">
      <h4>
        {{ $t("teacher.profile.available-lesson") }}
      </h4>
    </div>
    <div v-if="lesson.data.length > 0" >
      <template v-for="(item, key) in lesson.data">
        <router-link class="no-decoration" :to="{name:'lesson.detail', params:{lessonId:item.id}}" :key="key">
          <div class="item">
            <div class="row">
              <div class="col-5">
                  <img :src="item.thumbnail"/>
              </div>
              <div class="col-7">
                  <div>
                      <div class="price"> 
                        <label>{{ formatCurrency(item.currency_code, item.price) }}</label>
                      </div>
                  </div>
              </div>
              <div class="col-12 descr">
                  <div class="item-title">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </router-link>
      </template>
    </div>
    <div class="border p-3 text-break text-wrap text-center" v-else>
        {{ $t('teacher.profile.no-item-sale') }}
    </div>
    <div class="d-flex justify-content-center">
      <button class="btn btn-more" @click="handleLoadMore" :disabled="_noMoreLesson" v-if="!_noMoreLesson">
         <i class="fa fa-chevron-down" aria-hidden="true"></i>  {{ $t("teacher.profile.button.see-more") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      lesson: {
        data:[],
        meta: {}
      },
      filter: {
        page: 1,
      },
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      getAvailableLesson: "teacherProfile/getAvailableLesson",
    }),
    _noMoreLesson() {
       let { meta } = this.lesson;
      return meta?.pagination?.last > this.filter.page ? false : true;
    },
  },
  async created() {
    await this.fetchAvailableLesson({
      id: this.$route.params.teacherId,
      limit: 3,
      page: this.filter.page,
    });
    this.lesson = this.getAvailableLesson;
  },
  methods: {
    ...mapActions({
      fetchAvailableLesson: "teacherProfile/fetchAvailableLesson",
    }),
    async handleLoadMore() {
      if (this.loading) return;
      this.loading = true;
      this.filter.page = this.filter.page + 1;
      await this.fetchAvailableLesson({
        id: this.$route.params.teacherId,
        limit: 3,
        page: this.filter.page,
      });
      let items = this.lesson.data;
      this.lesson = {
        meta: this.getAvailableLesson.meta,
        data: items.concat(this.getAvailableLesson.data)
      };
      this.loading = false;
    },
    _formatTime(start, end) {
        return (
            this.dateInstance(start).format("HH:mm") +
            " - " +
            this.dateInstance(end).format("HH:mm")
        );
    },
  },
};
</script>

<style>
</style>